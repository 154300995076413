// Here you can add other styles

//Custom Amplify Auth Colors:
:root {
    --amplify-primary-color: #{$gray-base};
    --amplify-primary-tint: #{$primary-base};
    --amplify-primary-shade: #{$primary-base};
    --amplify-secondary-color: #{$secondary-base};
    --amplify-secondary-tint: #{$secondary-base};
    --amplify-secondary-shade: #{$secondary-base};
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
  }